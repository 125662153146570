import { useState } from "react";
import "./table.css";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TravelEntry, defaultData } from "./data";
import { TableCell } from "./cell";
import { EditCell } from "./edit";
import { FooterCell } from "./footer";
import useLocalStorage from "../logic/userLocalStorage";
import { validate } from "./validate";
import { IndeterminateCheckbox } from "./checkbox";
import countryList from "react-select-country-list";
const columnHelper = createColumnHelper<TravelEntry>();
const otherCountries: { label: string; value: string }[] = [
  { label: "-", value: "-" },
  { label: "other", value: "other" },
];

const columns = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor("start", {
    header: "From",
    id: "start",
    cell: TableCell,
    meta: {
      type: "date",
      required: true,
      validate,
      validationMessage: "Start date must be before end date",
    },
  }),
  columnHelper.accessor("end", {
    header: "To",
    id: "end",
    cell: TableCell,
    meta: {
      required: true,
      type: "date",
      validate,
      validationMessage: "Start date must be before end date",
    },
  }),
  columnHelper.accessor("destination", {
    id: "destination",
    header: "Destination",
    cell: TableCell,
    meta: {
      type: "select",
      required: false,
      //@ts-ignore
      options: otherCountries.concat(countryList().getData()),
    },
  }),
  columnHelper.accessor("purpose", {
    id: "purpose",
    header: "Purpose",
    cell: TableCell,
    meta: {
      type: "text",
      required: false,
    },
  }),
  columnHelper.display({
    id: "edit",
    cell: EditCell,
  }),
];

export const Table = ({
  data,
  setData,
}: {
  data: TravelEntry[];
  setData: React.Dispatch<React.SetStateAction<TravelEntry[]>>;
}) => {
  const [editedRows, setEditedRows] = useLocalStorage("edit", {});
  const [originalData, setOriginalData] = useState(() => data);
  const [validRows, setValidRows] = useState({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,

    meta: {
      editedRows,
      setEditedRows,
      validRows,
      setValidRows,
      updateData: (
        rowIndex: number,
        columnId: string,
        value: string,
        isValid: boolean
      ) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
        setValidRows((old: any) => ({
          ...old,
          [rowIndex]: { ...old[rowIndex], [columnId]: isValid },
        }));
      },
      revertData: (rowIndex: number, revert: boolean) => {
        if (revert) {
          setData((old) =>
            old.map((row, index) =>
              index === rowIndex ? originalData[rowIndex] : row
            )
          );
        } else {
          setOriginalData((old) =>
            old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
          );
        }
      },
      addRow: (useLast: boolean) => {
        const setFunc = (old: TravelEntry[]) => [
          ...old,
          old.length > 0 && useLast ? old[old.length - 1] : defaultData,
        ];

        setData(setFunc);
        setOriginalData(setFunc);
        // setEditedRows((old: {}) => ({ ...old, [old.length]: 1 }));
      },
      removeRow: (rowIndex: number) => {
        console.log(`removing row ${rowIndex}`);
        console.log(data);
        const setFilterFunc = (old: TravelEntry[]) =>
          old.filter((_row: TravelEntry, index: number) => index !== rowIndex);
        setData(setFilterFunc);
        setOriginalData(setFilterFunc);
      },
      clear: () => {
        setData([]);
        setOriginalData([]);
      },
      removeSelectedRows: (selectedRows: number[]) => {
        const setFilterFunc = (old: TravelEntry[]) =>
          old.filter((_row, index) => !selectedRows.includes(index));
        setData(setFilterFunc);
        setOriginalData(setFilterFunc);
      },
    },
  });
  return (
    <table style={{ justifyContent: "left" }}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th className="desktop" key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                className="mobile-flex"
                key={cell.id}
                headers={cell.column.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={table.getCenterLeafColumns().length} align="right">
            <FooterCell table={table} />
          </th>
        </tr>
      </tfoot>
    </table>
  );
};
