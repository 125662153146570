import { ChangeEvent } from "react";
import { TravelEntry, dateToPickerFormat } from "./data";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import Button from "@mui/material/Button";

import UploadIcon from "@mui/icons-material/Upload";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";

import "./csv.css";

const instructions =
  "csv files must contain only start/end/destination/purpose columns. the date in the csv should be in yyyy-mm-dd format.";

export const CsvButtons = ({
  data,
  setData,
}: {
  data: TravelEntry[];
  setData: React.Dispatch<React.SetStateAction<TravelEntry[]>>;
}) => {
  let toCsvFormat = (): string[][] => {
    let ret = [];
    const headers = ["start", "end", "destination", "purpose"];
    ret.push(headers);
    for (let i = 0; i < data.length; i++) {
      ret.push([
        data[i].start,
        data[i].end,
        data[i].destination,
        data[i].purpose,
      ]);
    }
    return ret;
  };

  let updateCsv = ({ data, errors }: { data: any[]; errors: [] }) => {
    if (errors && errors.length > 0) {
      alert("Failed to load, file is not formatted correctly: " + errors);
      return;
    }
    if (!data || data.length <= 0) {
      alert("File is empty!");
      return;
    }
    try {
      for (let i = 0; i < data.length; i++) {
        data[i]["start"] = dateToPickerFormat(data[i]["start"]);
        data[i]["end"] = dateToPickerFormat(data[i]["end"]);
        // data[i]["id"] = i.toString();
      }
      setData(data);
    } catch (error) {
      alert(`Problem parsing csv file ${error}`);
    }
  };

  let readCSV = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length && e.target?.files?.length > 0) {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: updateCsv,
      });
    }
  };
  return (
    <>
      <div className="csv-buttons">
        <span className="part" data-tooltip={instructions}>
          <Button
            variant="contained"
            id="loadCSV"
            onClick={() => {
              document.getElementById("loadCSVFile")?.click();
            }}
            startIcon={<UploadIcon />}
          >
            Load CSV
          </Button>
          <input
            id="loadCSVFile"
            type="file"
            accept=".csv"
            onChange={readCSV}
            name="Load CSV"
            style={{ display: "none" }}
          />
        </span>
        <span>
          <Button
            startIcon={<PrintIcon />}
            variant="contained"
            onClick={() => window.print()}
          >
            Print
          </Button>
        </span>
        <span className="part">
          <CSVLink data={toCsvFormat()} filename="travels_log.csv">
            <Button startIcon={<DownloadIcon />} variant="contained">
              Download CSV
            </Button>
          </CSVLink>
        </span>
      </div>
    </>
  );
};
