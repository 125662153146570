import "./App.css";
import { Table } from "./table/table";
import { defaultData } from "./table/data";
import { Calculations } from "./calcs";
import { CsvButtons } from "./table/csv";
import useLocalStorage from "./logic/userLocalStorage";
import { Instructions } from "./general/instructions";
import { PrintOnly } from "./general/PrintOnly";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { PageFooter } from "./general/Footer";

// import { OtherInputs } from "./general/other";

function App() {
  const [data, setData] = useLocalStorage("local_travels_log", [defaultData]);
  const [startDate] = useLocalStorage("start_date", "2020-1-1");

  return (
    <div className="App">
      <div className="header no-print">
        <h1>🇬🇧 ILR Travels Calculator</h1>
        <p>
          Create a report for your ILR/Citizenship case and keep track of your
          travel days.
        </p>
        <ul>
          <li>
            Enter your travels log in the table, you can edit by double clicking
          </li>
          <li>You can load exported csv later to carry on later</li>
          <li>
            If no violations, it means you are within limits for ILR application
          </li>
        </ul>
      </div>
      <div>
        {/* <OtherInputs startDate={startDate} setStartDate={setStartDate} /> */}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Table data={data} setData={setData} />
      </div>
      <div className="no-print">
        <CsvButtons data={data} setData={setData} />
      </div>
      <div className="no-print">
        <Instructions />
      </div>
      <div>
        <Calculations data={data} startDate={startDate} />
      </div>
      <div>
        <PrintOnly />
      </div>
      <PageFooter />
    </div>
  );
}

export default App;
