import { ChangeEvent, useState } from "react";

export const FooterCell = ({ table }: any) => {
  const meta = table.options.meta;
  const selectedRows = table.getSelectedRowModel().rows;

  let [useLast, setUseLast] = useState<boolean>(false);
  const removeRows = () => {
    meta.removeSelectedRows(
      table.getSelectedRowModel().rows.map((row: any) => row.index)
    );
    table.resetRowSelection();
  };

  const onAddRow = () => {
    meta?.addRow(useLast);
  };

  const onClear = () => {
    meta?.clear();
  };
  return (
    <div className="footer-buttons no-print">
      {selectedRows.length > 0 ? (
        <button className="remove-button" onClick={removeRows}>
          Remove Selected x
        </button>
      ) : null}

      <label>
        Use Last
        <input
          id="useLast"
          className="use-last"
          type="checkbox"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setUseLast(!useLast);
          }}
          checked={useLast}
        />
      </label>
      <button className="add-button" onClick={onAddRow}>
        <b>Add New +</b>
      </button>

      <button className="clear-button" onClick={onClear}>
        <b>clear table 🚫</b>
      </button>
    </div>
  );
};
