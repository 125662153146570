import { MouseEvent } from "react";

export const EditCell = ({ row, table }: any) => {
  const meta = table.options.meta;
  const setEditedRows = (e: MouseEvent<HTMLButtonElement>) => {
    const elName = e.currentTarget.name;
    meta?.setEditedRows((old: []) => ({
      ...old,
      [row.id]: !old[row.id],
    }));
    if (elName !== "edit") {
      meta?.revertData(row.index, e.currentTarget.name === "cancel");
    }
  };
  const removeRow = () => {
    meta?.removeRow(row.index);
  };
  const validRow = meta?.validRows[row.id];

  const disableSubmit = validRow
    ? Object.values(validRow)?.some((item) => !item)
    : false;

  return (
    <div className="edit-cell-container no-print">
      {meta?.editedRows[row.id] ? (
        <div className="edit-cell-action">
          <button onClick={setEditedRows} name="cancel">
            X
          </button>{" "}
          <button onClick={setEditedRows} name="done" disabled={disableSubmit}>
            ✔
          </button>
        </div>
      ) : (
        <div className="edit-cell-action">
          <button onClick={removeRow} name="remove">
            🗑️
          </button>
        </div>
      )}
    </div>
  );
};
