export type Option = {
  label: string;
  value: string;
};

export type TravelEntry = {
  start: string;
  end: string;
  destination: string;
  purpose: string;
};

class ValidationError extends Error {
  constructor(msg: string) {
    super(msg);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}
const zeroPad = (num: any, places: any) => String(num).padStart(places, "0");

function isValidDate(d: any) {
  return !isNaN(d) && d instanceof Date;
}

export function dateToPickerFormat(dateStr: string): string {
  var date = new Date(dateStr);
  if (isValidDate(date)) {
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let day = date.getDate();
    return `${year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`;
  }
  throw new ValidationError(
    `Date format ${dateStr} is invalid, please use yyyy-mm-dd`
  );
}

export const defaultData: TravelEntry = {
  start: "2019-02-01",
  end: "2019-02-20",
  purpose: "holidays",
  destination: "Bahamas",
};
