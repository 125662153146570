import {
  useState,
  MouseEvent,
  ChangeEvent,
  TouchEvent,
  useEffect,
} from "react";
import { Option } from "./data";

export const TableCell = ({ getValue, row, column, table }: any) => {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;

  const [value, setValue] = useState(initialValue);
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    displayValidationMessage(e);
    tableMeta?.updateData(row.index, column.id, value, e.target.validity.valid);
  };
  const meta = table.options.meta;

  const handleEdit = (elName: string) => {
    meta?.setEditedRows((old: []) => ({
      ...old,
      [row.id]: !old[row.id],
    }));
    if (elName !== "edit") {
      meta?.revertData(row.index, elName === "cancel");
    }
  };
  const setEditedRows = (e: MouseEvent<HTMLSelectElement>) => {
    const elName = e.currentTarget.name;
    handleEdit(elName);
  };

  const setEditedTouch = (e: TouchEvent<HTMLSpanElement>) => {
    handleEdit(e.currentTarget.localName);
  };

  const displayValidationMessage = <
    T extends HTMLInputElement | HTMLSelectElement
  >(
    e: ChangeEvent<T>
  ) => {
    if (columnMeta?.validate) {
      const isValid = columnMeta.validate(
        row.original,
        column.id,
        e.target.value
      );
      if (isValid) {
        e.target.setCustomValidity("");
        setValidationMessage("");
      } else {
        e.target.setCustomValidity(columnMeta.validationMessage);
        setValidationMessage(columnMeta.validationMessage);
      }
    } else if (e.target.validity.valid) {
      setValidationMessage("");
    } else {
      console.log(e.target.validationMessage);
      setValidationMessage(e.target.validationMessage);
    }
  };

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    displayValidationMessage(e);
    setValue(e.target.value);
    tableMeta?.updateData(
      row.index,
      column.id,
      e.target.value,
      e.target.validity.valid
    );
  };

  if (tableMeta?.editedRows[row.id]) {
    return columnMeta?.type === "select" ? (
      <select
        onChange={onSelectChange}
        value={initialValue}
        required={columnMeta?.required}
        title={validationMessage}
        name="select"
      >
        {columnMeta?.options?.map((option: Option) => (
          <option key={option.label} value={option.label}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <input
        value={value}
        onChange={(e) => {
          displayValidationMessage(e);
          setValue(e.target.value);
        }}
        name="select"
        onBlur={onBlur}
        type={columnMeta?.type || "text"}
        required={columnMeta?.required}
        title={validationMessage}
      />
    );
  }

  return (
    <span
      key={row.id}
      onTouchStart={setEditedTouch}
      onDoubleClick={setEditedRows}
    >
      {value}
    </span>
  );
};
