import { TravelEntry } from "../table/data";

export type Travel = {
  start: Date;
  end: Date;
};

function calcTravelDuration(travel: Travel): number {
  const a = travel.start;
  const b = travel.end;
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  // ignore the start day for travel as it is counted inside country
  return Math.max(0, Math.floor((utc2 - utc1) / _MS_PER_DAY) - 1);
}

export function calcTotalTravelledDays(travels: TravelEntry[]): number {
  let travels_sorted = getTravelSorted(travels);
  travels_sorted = mergeTravels(travels_sorted);

  let ret = 0;
  for (const travel of travels_sorted) {
    ret += calcTravelDuration({
      start: new Date(travel.start),
      end: new Date(travel.end),
    });
  }
  return ret;
}

function maxDate(a: Date, b: Date): Date {
  return a > b ? a : b;
}

function minDate(a: Date, b: Date): Date {
  return a < b ? a : b;
}

function mergeTravels(travels: Travel[]): Travel[] {
  if (travels.length < 1) {
    return [];
  }
  // travels expected to be sorted by start date and length > 0
  let [curStart, curEnd] = [travels[0].start, travels[0].end];
  let ret: Travel[] = [];
  for (let i = 1; i < travels.length; i++) {
    if (travels[i].start > curEnd) {
      ret.push({ start: curStart, end: curEnd });
      [curStart, curEnd] = [travels[i].start, travels[i].end];
    } else {
      curEnd = maxDate(curEnd, travels[i].end);
    }
  }
  ret.push({ start: curStart, end: curEnd });
  return ret;
}

function getTravelSorted(travels: TravelEntry[]): Travel[] {
  let data: Travel[] = travels.map((t) => ({
    start: new Date(t.start),
    end: new Date(t.end),
  }));
  data = data.sort((a: Travel, b: Travel) => {
    if (a.start !== b.start) {
      return a.start.getTime() - b.start.getTime();
    } else {
      return a.end.getTime() - b.end.getTime();
    }
  });
  return data;
}

export type StayWindow = {
  travels: Travel[];
  duration_days: number;
};

function calcTravelsDurationWindow(
  travels: Travel[],
  window_in_years: number,
  startDate: Date | null = null
): number {
  if (travels.length < 1) {
    return 0;
  }
  const end = getEndDate(travels[0].start, window_in_years);
  let ret = 0;
  for (let i = 0; i < travels.length; i++) {
    ret += calcTravelDuration({
      start: travels[i].start,
      end: minDate(travels[i].end, end),
    });
  }
  return ret;
}

function getEndDate(cur: Date, window_in_years: number): Date {
  let nextYear = new Date(cur);
  nextYear.setFullYear(nextYear.getFullYear() + window_in_years);
  return nextYear;
}

// function changeDays(date: Date, days: number): Date {
//   let ret = new Date(date);
//   ret.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
//   return ret;
// }

// export function calcIlrEligible(
//   travels: TravelEntry[],
//   violations: StayWindow[],
//   numYears: number
// ): Date {
//   //travels should be sorted
//   if (violations.length > 0) {
//     let lastViolationEnd = violations[0].travels[0].end;
//     for (let i = 0; i < violations.length; i++) {
//       let travels = violations[i].travels;
//       lastViolationEnd = maxDate(
//         lastViolationEnd,
//         travels[travels.length - 1].end
//       );
//     }
//     return changeDays(lastViolationEnd, -179 + Math.floor(365.24 * numYears));
//   } else if (travels.length > 0) {
//     let earliest = travels[0].start;
//     for (let i = 0 ; i < travels.length; i++) {
//       earliest = minDate(earliest, travels[i].start)
//     }
//     return changeDays();
//   }
//   return new Date();
// }

function doCalc(
  travels: Travel[],
  window_in_years: number,
  daysThreshold: number
): [StayWindow, StayWindow[]] {
  // travels expected to be sorted and length >0
  let maxStay: StayWindow = {
    travels: [],
    duration_days: 0,
  };

  let i = 0,
    j = 1;

  let violationStays: StayWindow[] = [];
  while (i < travels.length) {
    let considered = travels.slice(i, j);
    let duration = calcTravelsDurationWindow(considered, window_in_years);
    if (duration > daysThreshold) {
      violationStays = violationStays.concat([
        { travels: considered, duration_days: duration },
      ]);
    }
    if (duration > maxStay.duration_days) {
      maxStay = {
        travels: considered,
        duration_days: duration,
      };
    }
    let curEnd = getEndDate(considered[0].start, window_in_years);
    if (
      curEnd < considered[considered.length - 1].start ||
      j >= travels.length
    ) {
      i++;
    } else {
      j++;
    }
  }

  return [maxStay, violationStays];
}

export function calcLongestStayInWindow(
  travels: TravelEntry[],
  window_in_years: number,
  leaveThreshold: number,
  _: string | null = null
): [StayWindow | null, StayWindow[] | null] {
  if (travels.length === 0) {
    return [null, null];
  }
  // if (startDate) {
  //   // we need to add start date as void travel to make our algorithm start at that date
  //   let voidTravels: TravelEntry[] = [
  //     {
  //       start: startDate,
  //       end: startDate,
  //       reason: "start date",
  //       remarks: "start date",
  //     },
  //   ];
  //   travels = voidTravels.concat(travels);
  // }
  let travels_sorted = getTravelSorted(travels);
  travels_sorted = mergeTravels(travels_sorted);
  return doCalc(travels_sorted, window_in_years, leaveThreshold);
}
