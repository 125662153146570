import { useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import useLocalStorage from "../logic/userLocalStorage";

export const Instructions = () => {
  let [run, setRun] = useLocalStorage("first-run", true);
  let [steps] = useState<Step[]>([
    {
      target: ".add-button",
      content: "Add rows to the table",
      placement: "center",
    },
    {
      target: ".use-last",
      content: "Uses same info as last row for adding new rows",
    },
    {
      target: "thead",
      content: "double click to edit",
    },
    {
      target: ".csv-buttons",
      content: "Write your travels to CSV or load from CSV",
    },
    {
      target: ".calcs",
      content: "Calculations appear here",
    },
  ]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  return (
    <>
      <Joyride
        run={run}
        callback={handleJoyrideCallback}
        showSkipButton={true}
        continuous={true}
        steps={steps}
        showProgress={true}
      />
    </>
  );
};
