import { useEffect, useState } from "react";
import {
  StayWindow,
  calcLongestStayInWindow,
  calcTotalTravelledDays,
} from "./logic/core";
import { TravelEntry } from "./table/data";

type CalculationsSummary = {
  totalStay: number;
  longestStay: StayWindow | null;
  violationStays: StayWindow[] | null;
  // eligibleDate: Date | null;
};

export const Calculations = ({
  data,
  startDate,
}: {
  data: TravelEntry[];
  startDate: string;
}) => {
  let [calcState, setCalcState] = useState<CalculationsSummary>({
    totalStay: 0,
    longestStay: null,
    violationStays: [],
    // eligibleDate: null,
  });

  const calcWindowYears = 1;

  function onUpdate(data: TravelEntry[], startDate: string) {
    // console.log(data);
    // console.log(calcTotalTravelledDays(data));
    // console.log(startDate);
    let [longest, violations] = calcLongestStayInWindow(
      data,
      calcWindowYears,
      180,
      startDate
    );
    let summary: CalculationsSummary = {
      totalStay: calcTotalTravelledDays(data),
      longestStay: longest,
      violationStays: violations,
      // eligibleDate: eligibleDate,
    };

    setCalcState(summary);
  }

  function printStayWindow(stay: StayWindow, key: number) {
    return (
      <p key={key}>
        <b>{stay.duration_days}</b> day(s) starting from{" "}
        <b>{stay.travels[0].start.toLocaleDateString()}</b>, ending at{" "}
        <b>{stay.travels[stay.travels.length - 1].end.toLocaleDateString()}</b>.
      </p>
    );
  }

  useEffect(() => {
    onUpdate(data, startDate);
  }, [data, startDate]);
  let [showInPrint, setShowInPrint] = useState<boolean>(false);
  return (
    <div className={showInPrint ? "" : "no-print"}>
      <div>
        <h1>Calculations:</h1>
        <label className="no-print">
          Show calculations in print:
          <input
            id="showPrintCalcs"
            checked={showInPrint}
            onChange={(e) => {
              setShowInPrint(e.target.checked);
            }}
            type="checkbox"
          />
        </label>
      </div>
      <p>
        Total days outside of UK: <b>{calcState.totalStay}</b> days
      </p>
      {calcState.longestStay ? (
        <div>
          Longest stay in a {calcWindowYears} year window:{" "}
          {printStayWindow(calcState.longestStay, 0)}
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          backgroundColor:
            (calcState.violationStays?.length ?? 0) > 0
              ? "pink"
              : "greenyellow",
          margin: "20px",
        }}
      >
        {(calcState.violationStays?.length ?? 0) > 0 ? (
          <>Found over-travel violations:</>
        ) : (
          <>No over-travel violations.</>
        )}
        {calcState.violationStays?.map((stay, idx) =>
          printStayWindow(stay, idx)
        )}
      </div>
    </div>
  );
};
