import { TravelEntry } from "./data";

export const validate = (
  entry: TravelEntry,
  columnName: string,
  value: string
) => {
  const sdate =
    columnName === "start" ? new Date(value) : new Date(entry.start);
  const edate = columnName === "end" ? new Date(value) : new Date(entry.end);
  return sdate < edate;
};
